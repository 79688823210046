import cx from 'classnames';
import { useContext } from 'react';
import { useHandleInternalLinks } from '@superhuit/starterpack-hooks';
import Container from '@/next-components/container';
import Blocks from '@/next-components/blocks';
import { ThemeContext } from '@superhuit/starterpack-context';
import { omit } from '@superhuit/starterpack-utils';
import { getStyles } from './styles.css';
import { getStyles as getPageStyles } from '../SinglePage/styles.css';
import { SinglePageThemeControls } from '../SinglePage/theming';

export default function Production({ node, preview }) {
	const blocks = node?.blocksJSON ? JSON.parse(node.blocksJSON) : [];

	const handleInternalLinks = useHandleInternalLinks();

	// Merge: defaults + theme (variant base + variant active)
	const { rawTheme, theme } = useContext(ThemeContext);
	const blockTheme = theme?.['templates']?.[SinglePageThemeControls.slug]; // theme
	const tokensTheme = rawTheme?.tokens; // tokens
	const finalTheme = omit(blockTheme, ['variants']);
	// console.log('::: Final Theme ::: ', finalTheme);

	const rootClass = cx(
		'supt-singleProduction',
		getPageStyles(tokensTheme, finalTheme),
		getStyles()
	);

	return (
		<>
			<Blocks blocks={blocks} includes={/^supt\/header-c/g} />
			<Container className={rootClass} onClick={handleInternalLinks}>
				<Blocks blocks={blocks} excludes={/^supt\/header-c/g} />
			</Container>
		</>
	);
}
